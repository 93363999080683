import React from 'react'
import TenexLink from './tenex-link'

export default function Docs() {
  return (
    <ul>
      <li>
        <a href="https://corp.mongodb.com/">Corp</a>
      
  </li><li>
        <a href="https://corp.mongodb.com/home/google/0oa3x2mldvwON73xN297/54">Calendar</a>
  &middot;
        <a href="https://docs.google.com/">Docs</a>
  &middot;
        <a href="https://corp.mongodb.com/home/google/0oa3x2mldvwON73xN297/52">Drive</a>
      </li>
      <li>
        <a href="https://docs.google.com/document/d/1rXBoMDls4u_jbF9VKJpWCBE2oDCjMFN5fUTTP07c7D4/edit">
          Team notes
        </a>
      </li>
      <li>
        <a href="https://docs.google.com/document/d/1sbDTkzlPgpM688udrZD8tHt0k7s7YQs4iK5VLoNnuHs/edit">
          Andreas 1:1
        </a>
      </li>
      <li>
        <a href="https://docs.google.com/a/10gen.com/document/d/1h9wjZlQChrxp2ryTWnexqHXVKhTRW-Zh30M2Ee3i7CY/edit?usp=drive_web">
          Bernie 1:1
        </a>
      </li>
      <li>
        <a href="https://jira.mongodb.org/secure/Dashboard.jspa?selectPageId=20138">
          Docs Review Board
        </a>
      </li>
      <li>
        <a href="https://rooms.corp.mongodb.com/">Conference rooms</a>
      </li>
    </ul>
  )
}
