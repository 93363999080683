import React from 'react'
import TenexLink from './tenex-link'
import JiraQueryLink from './jira-query-link'

export default function Issues() {
  return (
    <div>
      <ul>
        <li>
          <a href="http://tenex.bsdpower.com/jira/query">Jira query</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="https://jira.mongodb.org/secure/RapidBoard.jspa?rapidView=672&projectKey=RUBY">
            Kanban board
          </a>
        </li>
          <li>
          <a href="https://jira.mongodb.org/secure/RapidBoard.jspa?rapidView=672&view=planning">
            Backlog
          </a>
        </li>

        <li>
          <a href="https://jira.mongodb.org/secure/Dashboard.jspa?selectPageId=26219">
            Drivers specs
          </a>
        </li>
        <li>
          <a href="https://jira.mongodb.org/secure/RapidBoard.jspa?rapidView=972&selectedIssue=WRITING-3385&quickFilter=6616">
            Writing board
          </a>
        </li>
        <li>
          <a href="https://github.com/pulls/review-requested">
            Review requests
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <JiraQueryLink q="ame open order by status">
            Assigned to me
          </JiraQueryLink>
        </li>
        <li>
          <JiraQueryLink q="ame open and status in (investigating)">
            My investigating
          </JiraQueryLink>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26630">
            Need triage
          </a>
        </li>
        <li>
          <JiraQueryLink q="drivers and component in ('server selection') and status='needs triage'">
            Need triage - server selection
          </JiraQueryLink>
        </li>
        <li>
          <JiraQueryLink q="drivers and component in ('transactions') and status='needs triage'">
            Need triage - transactions
          </JiraQueryLink>
        </li>
        <li>
          <JiraQueryLink q="drivers and component in ('atlas testing') and status='needs triage'">
            Need triage - atlas testing
          </JiraQueryLink>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26357">
            Bugs by priority
          </a>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26436">
            Ruby unresolved
          </a>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26437">
            Mongoid unresolved
          </a>
        </li>
        <li>
          <JiraQueryLink q="ruby open epic">Ruby epics</JiraQueryLink>
        </li>
        <li>
          <JiraQueryLink q="mongoid open epic">Mongoid epics</JiraQueryLink>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26457">
            Ruby planning
          </a>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26450">
            Mongoid planning
          </a>
        </li>
        <li>
          <JiraQueryLink q="open rm and labels in(next-release)">
            Next release
          </JiraQueryLink>
        </li>
        <li>
          <JiraQueryLink q='ame AND resolution is not EMPTY AND resolutiondate >= -7d'>
            My recently closed
          </JiraQueryLink>
          {' \u00b7 '}
          <JiraQueryLink q='ame AND resolution is not EMPTY AND resolutiondate >= -14d'>
            2w
          </JiraQueryLink>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=26631">
            My recently closed export
          </a>
        </li>
        <li>
          <a href="https://jira.mongodb.org/issues/?filter=29528">
            Recently closed - python/ruby
          </a>
        </li>
      </ul>
    </div>
  )
}
