import React from 'react'
import TenexLink from './tenex-link'

export default function Documentation() {
  return (
    <table cellSpacing="5">
      <tbody>
        <tr>
          <td>
            <a href="https://silverhammermba.github.io/emberb/c/">MRI C API</a>{' '}
            <a href="https://javadoc.io/doc/org.jruby/jruby-core">JRuby API</a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/evergreen-ci/evergreen/wiki">Evergreen</a>:{' '}
            <a href="https://github.com/evergreen-ci/evergreen/wiki/REST-V2-Usage">REST v2</a>,{' '}
            <a href="https://github.com/evergreen-ci/evergreen/wiki/REST-V1-Usage">REST v1</a>,{' '}
            <a href="https://github.com/evergreen-ci/evergreen/wiki/Project-Configuration-Files">Project file</a>
            
          </td>
        </tr>
      </tbody>
    </table>
  )
}
