import React from 'react'
import TenexLink from './tenex-link'

export default function Repos() {
  return (
    <div>
      <table cellSpacing="5">
        <tbody>
          <tr>
            <td>
              <a href="https://github.com/mongodb/mongo-ruby-driver/">
                Ruby driver
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb/mongo-ruby-driver/pulls">
                Pulls
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb/mongo-ruby-driver/pulls/p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/mongo-ruby-driver">
                Tenex
              </TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/mongo-ruby-driver?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
            <td>
              <a href="https://docs.mongodb.com/ruby-driver/master/">Docs</a>
            </td>
            <td>
              <a href="https://github.com/mongodb/docs-ruby">Repo</a>
            </td>
            <td>
              <TenexLink href="/jira/query?q=ruby open and (component is empty or component not in (bson)))">
                Jira
              </TenexLink>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://github.com/mongodb/mongoid/">Mongoid</a>
            </td>
            <td>
              <a href="https://github.com/mongodb/mongoid/pulls">Pulls</a>
            </td>
            <td>
              <a href="https://github.com/mongodb/mongoid/pulls/p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/mongoid">Tenex</TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/mongoid?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
            <td>
              <a href="https://docs.mongodb.com/mongoid/master/">Docs</a>
            </td>
            <td>
              <a href="https://github.com/mongodb/docs-mongoid">Repo</a>
            </td>
            <td>
              <TenexLink href="/jira/query?q=mongoid open">Jira</TenexLink>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://github.com/mongodb/bson-ruby/">BSON</a>
            </td>
            <td>
              <a href="https://github.com/mongodb/bson-ruby/pulls">Pulls</a>
            </td>
            <td>
              <a href="https://github.com/mongodb/bson-ruby/pulls/p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/bson-ruby">Tenex</TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/bson-ruby?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
            <td></td>
            <td></td>
            <td>
              <TenexLink href="/jira/query?q=ruby open bson">Jira</TenexLink>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://github.com/mongodb-labs/mongo-ruby-spec-shared/">MRSS</a>
            </td>
            <td>
              <a href="https://github.com/mongodb-labs/mongo-ruby-spec-shared/pulls">Pulls</a>
            </td>
            <td>
              <a href="https://github.com/mongodb-labs/mongo-ruby-spec-shared/pulls/p-mongo">
                Mine
              </a>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://github.com/mongodb/mongo-ruby-kerberos/">
                Kerberos
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb/mongo-ruby-kerberos/pulls">
                Pulls
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb/mongo-ruby-kerberos/pulls/p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/mongo-ruby-kerberos">
                Tenex
              </TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/mongo-ruby-kerberos?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://github.com/10gen/mongo-ruby-toolchain">
                Toolchain
              </a>
            </td>
            <td></td>
            <td></td>
            <td>
              <TenexLink href="/repos/10gen/mongo-ruby-toolchain">
                Tenex
              </TenexLink>
            </td>
            <td></td>
            <td></td>
            <td colspan="2">
              <TenexLink href="/projects/mongo-ruby-toolchain">
                Tenex-EG
              </TenexLink>
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://github.com/10gen/buildhost-configuration">
                Buildhost Config
              </a>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <a href="https://github.com/mongodb/specifications">
                Specifications
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb/specifications/pulls">
                Pulls
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb/specifications/pulls?creator=p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/specifications">Tenex</TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb/specifications?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://github.com/mongodb-labs/drivers-atlas-testing">
                Astrolabe
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb-labs/drivers-atlas-testing/pulls">
                Pulls
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb-labs/drivers-atlas-testing/pulls?creator=p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb-labs/drivers-atlas-testing">
                Tenex
              </TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb-labs/drivers-atlas-testing?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
            <td>
              <a href="https://mongodb-labs.github.io/drivers-atlas-testing/">
                Docs
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://github.com/mongodb-labs/drivers-evergreen-tools">
                D-E-T
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb-labs/drivers-evergreen-tools/pulls">
                Pulls
              </a>
            </td>
            <td>
              <a href="https://github.com/mongodb-labs/drivers-evergreen-tools/pulls?creator=p-mongo">
                Mine
              </a>
            </td>
            <td>
              <TenexLink href="/repos/mongodb-labs/drivers-evergreen-tools">
                Tenex
              </TenexLink>
            </td>
            <td>
              <TenexLink href="/repos/mongodb-labs/drivers-evergreen-tools?creator=p-mongo">
                Mine
              </TenexLink>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <a href="https://github.com/p-mongo/tenex">Tenex</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <a href="https://github.com/p-mongo/dev">Dev scripts</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <a href="https://github.com/p-mongo/tests">Tests</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
