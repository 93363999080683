import _ from 'underscore'
import * as d from './deps'

export function merge(one, two) {
  return Object.assign({}, one, two)
}

export function merge_subkey(left, subkey, right) {
  if (!_.isArray(subkey)) {
    subkey = [subkey]
  }
  let last = subkey.pop()
  let new_left = merge(left)
  let a_left = new_left,
    a_right = right
  _.each(subkey, (item) => {
    a_left[item] = merge(a_left[item] || {})
    a_left = a_left[item]
  })
  a_left[last] = right
  return new_left
}

export function make_hash() {
  let i = 0
  let out = {}
  while (i < arguments.length) {
    let k = arguments[i++]
    let v = arguments[i++]
    out[k] = v
  }
  return out
}

export function each_kv(hash, cb) {
  return _.each(_.keys(hash), (key) => cb(key, hash[key]))
}

export function fetch(hash, key) {
  let v = hash[key]
  if (v === undefined) {
    throw new Error(
      `Hash value for ${key} was undefined: ${JSON.stringify(hash)}`,
    )
  }
  return v
}
