import React from 'react'
import TenexLink from './tenex-link'
import Repos from './repos'
import Projects from './projects'
import Docs from './docs'
import Specs from './specs'
import Roadmap from './roadmap'
import LocalDocs from './local-docs'
import Issues from './issues'
import Documentation from './documentation'

export default function Home() {
  return (
    <div>
      <div style={{ float: 'left' }}>
        <Issues />

      {false&&<div>
        <p>Roadmap</p>
        <Roadmap /></div>
      }
        <p>Local docs</p>
        <LocalDocs />
      </div>

      <div style={{ float: 'left' }}>
        <Repos />

        <ul>
          <li>
            <TenexLink href="/spawn">Spawn hosts</TenexLink>
          </li>
          <li>
            <TenexLink href="/ruby-toolchain-urls">
              Ruby toolchain URLs
            </TenexLink>
          </li>
          <li>
            <TenexLink href="/wiki">Edit wiki pages</TenexLink>
          </li>
        </ul>

        <p>Docs</p>
        <Docs />

        <p>
          Tickets:{' '}
          <a href="https://jira.mongodb.org/secure/CreateIssue!default.jspa">
            New
          </a>{' '}
          <a href="https://jira.mongodb.org/servicedesk/customer/portal/13">
            HELP
          </a>{' '}
          <a href="https://jira.mongodb.org/servicedesk/customer/portal/32">
            PRODTRIAGE
          </a>{' '}
          <a href="https://jira.mongodb.org/servicedesk/customer/portal/1">
            OFFICEIT
          </a>
        </p>
      </div>

      <div style={{ float: 'left', width: '40%' }}>
        <p>Specs</p>
        <Specs />
        <p>Documentation</p>
        <Documentation />
        <p>Projects</p>
        <Projects />
      </div>
    </div>
  )
}
