import * as d from '../deps'
import * as u from '../util'
import _ from 'underscore'
import React from 'react'
import * as c from '../components'

export default class Handler extends React.Component {
  render() {
    return <c.Home />
  }
}

Handler.propTypes = {}
