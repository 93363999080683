import React from 'react'
import Nav from './components/nav'

export default class AppBase extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        {false && <Nav />}
        {this.props.children}
      </div>
    )
  }
}
