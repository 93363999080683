import * as d from '../deps'
import React from 'react'

export default function TenexLink(props) {
  let cookie = d.Cookies.get('host')
  let host = cookie || 'http://tenex.bsdpower.com'
  //host = 'http://speed:9393'

  return <a href={`${host}${props.href}`}>{props.children}</a>
}
