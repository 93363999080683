import React from 'react'
import { Router } from './deps'
import { hot } from 'react-hot-loader'

import history from './history'
import routes from './routes'

class Root extends React.Component {
  render() {
    return <Router history={history} routes={routes} />
  }
}

export default hot(module)(Root)
