import React from 'react'
import TenexLink from './tenex-link'

export default function Projects() {
  return (
    <table cellSpacing="5">
      <tbody>
        <tr>
          <td>
            <a href="https://github.com/rubygems/rubygems">RubyGems</a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/jruby/jruby">JRuby</a>{' '}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
