import React from 'react'
import TenexLink from './tenex-link'

export default function Specs() {
  return (
    <table cellSpacing="5">
      <tbody>
        <tr>
          <td>
            <a href="http://bsonspec.org/spec.html">BSON</a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/extended-json.rst">
              Ext JSON
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/bson-decimal128/decimal128.rst">
              Decimal
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/client-side-encryption/subtype6.rst">
              Ciphertext
            </a>
          </td>
          {false && (
            <td>
              <a href="https://github.com/mongodb/specifications/blob/master/source/bson-corpus/bson-corpus.rst">
                Corpus
              </a>
            </td>
          )}
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/objectid.rst">
              ObjectID
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/uuid.rst">
              UUID
            </a>{' '}
          </td>{' '}
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/mongodb-handshake/handshake.rst">
              Handshake
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/message/OP_MSG.rst">
              OP_MSG
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/compression/OP_COMPRESSED.rst">
              Compression
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/wireversion-featurelist.rst">
              Wire Version
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/ocsp-support/ocsp-support.rst">
              OCSP
            </a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/auth/auth.rst">
              Auth
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/connection-string/connection-string-spec.rst">
              Connection string
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/initial-dns-seedlist-discovery/initial-dns-seedlist-discovery.rst">
              SRV
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/polling-srv-records-for-mongos-discovery/polling-srv-records-for-mongos-discovery.rst">
              SRV polling
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/uri-options/uri-options.rst">
              URI options
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/server-discovery-and-monitoring/server-discovery-and-monitoring.rst">
              SDAM
            </a>
            {': '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/server-discovery-and-monitoring/server-discovery-and-monitoring-summary.rst">
              Summary
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/server-discovery-and-monitoring/server-discovery-and-monitoring-monitoring.rst">
              Events
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/server-discovery-and-monitoring/server-monitoring.rst">
              Server Monitoring
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/load-balancers/load-balancers.rst">
              Load Balancers
            </a>{' '}
          </td>
          {false && (
            <td>
              <a href="https://github.com/mongodb/specifications/tree/master/source/server-discovery-and-monitoring/tests">
                Tests
              </a>
            </td>
          )}
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/server-selection/server-selection.rst">
              Server Selection
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/max-staleness/max-staleness.rst">
              Max Staleness
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/driver-read-preferences.rst">
              Read Pref
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/read-write-concern/read-write-concern.rst">
              R/W Concern
            </a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/connection-monitoring-and-pooling/connection-monitoring-and-pooling.rst">
              CMAP
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/command-monitoring/command-monitoring.rst">
              Command Monitoring
            </a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/sessions/driver-sessions.rst">
              Sessions
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/causal-consistency/causal-consistency.rst">
              Causal Consistency
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/retryable-reads/retryable-reads.rst">
              Retryable Reads
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/retryable-writes/retryable-writes.rst">
              Writes
            </a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/change-streams/change-streams.rst">
              Change Streams
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/transactions/transactions.rst">
              TX
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/transactions-convenient-api/transactions-convenient-api.rst">
              TX API
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/versioned-api/versioned-api.rst">
              Versioned API
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/find_getmore_killcursors_commands.rst">
              Find GetMore KillCursors
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/crud/crud.rst">
              CRUD
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/collation/collation.rst">
              Collation
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/out-aggregation-pipeline-operator.rst">
              $out
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/server_write_commands.rst">
              Write Commands
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/driver-bulk-update.rst">
              Bulk Write
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/gridfs/gridfs-spec.rst">
              GridFS
            </a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/enumerate-databases.rst">
              List Databases
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/enumerate-collections.rst">
              Collections
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/enumerate-indexes.rst">
              Indexes
            </a>
            {', '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/index-management.rst">
              Index Mgmt
            </a>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/client-side-encryption/client-side-encryption.rst">
              CSE
            </a>{' '}
            <a href="https://github.com/mongodb/libmongocrypt/blob/master/integrating.md">
              LMC integration
            </a>{' '}
            <a href="https://github.com/mongodb/libmongocrypt/blob/master/src/mongocrypt.h.in">
              LMC API
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="https://github.com/mongodb/specifications/blob/master/source/unified-test-format/unified-test-format.rst">
              Unified Tests
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/atlas-data-lake-testing/tests/README.rst">
              ADL Tests
            </a>{' '}
            <a href="https://github.com/mongodb/specifications/blob/master/source/benchmarking/benchmarking.rst">
              Benchmarks
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
