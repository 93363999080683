import React from 'react'
import TenexLink from './tenex-link'

export default function LocalDocs() {
  return (
    <ul>
      <li>
        Driver: <a href="http://serene.here/docs/driver/tutorial/">Tutorial</a>{' '}
        &mdash; <a href="http://serene.here/docs/driver/api/">API</a>
        <ul
        ><li>
        <a href="https://p-mongo.github.io/rendered-docs/driver/tutorial/">Rendered</a>{' '}
        &mdash; <a href="https://p-mongo.github.io/rendered-docs/driver/api/">API</a>
        </li></ul>
      </li>
      <li>
        Mongoid: <a href="http://serene.here/docs/mongoid/tutorial/">Tutorial</a>{' '}
        &mdash; <a href="http://serene.here/docs/mongoid/api/">API</a>
        <ul
        ><li>
        <a href="https://p-mongo.github.io/rendered-docs/mongoid/tutorial/">Rendered</a>{' '}
        &mdash; <a href="https://p-mongo.github.io/rendered-docs/mongoid/api/">API</a>
        </li></ul>
      </li>
    </ul>
  )
}
